 <template>
   <div class="bottom">
      <div class="bottominfo">
<!--        {{webinfos}}-->
        <div class="bottom_left">
          <div> <img height="120" src="@/static/images/newlogo-150.png" /></div>
<!--          <div class="about">{{webinfos.content.about}}</div>-->
          <div class="about">Prioritize physical and mental health for a better life</div>
          <div class="about">关注全民身体、心理和精神健康，追求更高的生命和生活质量</div>
          <div class="about">Privilégier la santé physique et mentale pour une vie meilleure</div>
<!--          <div><a-icon type="phone" /> {{webinfos.content.tel}}</div>-->
          <div><a-icon type="mail" /> {{webinfos.content.email}}</div>
<!--          <div><a-icon type="environment" /> {{webinfos.content.adder}}</div>-->
        </div>
        <div class="bottom_right">
          <div v-for="(item,index) in navbar" :key="index">
            <h3 v-if="item.child.length !== 0">
               <span v-if="language ==='cn'"  @click="gotocategory(item.id,item.is_jump,item.jump_url,item.key)">
                    {{ item.name.cn }}
                  </span>
              <span v-if="language ==='en'"  @click="gotocategory(item.id,item.is_jump,item.jump_url,item.key)">
                    {{ item.name.en }}
                  </span>
              <span v-if="language ==='fr'"  @click="gotocategory(item.id,item.is_jump,item.jump_url,item.key)">
                    {{ item.name.fr }}
                  </span>
             </h3>
            <div class="barblock">
              <div v-for="(items,indexs) in item.child" :key="indexs" class="barlink">
                 <span v-if="language ==='cn'"  @click="gotocategory(items.id,items.is_jump,items.jump_url,item.key)">
                    {{ items.name.cn }}
                  </span>
                <span v-if="language ==='en'"  @click="gotocategory(items.id,items.is_jump,items.jump_url,item.key)">
                    {{ items.name.en }}
                  </span>
                <span v-if="language ==='fr'"  @click="gotocategory(items.id,items.is_jump,items.jump_url,item.key)">
                    {{ items.name.fr }}
                  </span>
<!--                {{items.title}}-->
              </div>
              <div  v-if="item.child.length > 0"  class="barmain"></div>
            </div>
          </div>

        </div>
      </div>
      <div class="copyright">
        <div class="text">© 2023 ISRP All Rights Reserved</div>
      </div>
   </div>
</template>

<script>

import Config from "@/config";

export default {
  inject: ["reload"],
  props: ['category_id'],

  name: "topnav",
  data() {
    return {
      language: localStorage.language,

      webinfos: JSON.parse(sessionStorage.webinfos),
      Links: JSON.parse(sessionStorage.Links),
      Cooperation: JSON.parse(sessionStorage.Cooperation),
      navbar: JSON.parse(sessionStorage.navbar),
      // navbarselected: JSON.parse(sessionStorage.navbarselected),

      // navbar: "",
      navbarselected: "",
      displaylanguage: "中文",
      actice_id: "",
      dictionary: Config.data.dictionary,

    }
  },
  created() {
    if(sessionStorage){
      this.navbar = JSON.parse(sessionStorage.getItem('navbar'))
      this.navbarselected = JSON.parse(sessionStorage.getItem('navbarselected'))
      this.webinfos = JSON.parse(sessionStorage.getItem('webinfos'))
    }
    // console.log("navbarbottom",this.navbar)
    // console.log("navbarbottom",sessionStorage)
    // console.log("webinfos",this.webinfos)
    // this.getwebindex()
    // this.getwebinfo("1")
  },
  watch: {
    // 利用watch方法检测路由变化：
    $route: function (to, from) {
      console.log("watch", to, from)

      if (to.fullPath !== from.fullPath) {
        this.currentPage = this.$route.query.code;//获取参数
      }

    }
  },

  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate", to, from, next)
    next();
    if (to.fullPath !== from.fullPath) {
      this.currentPage = this.$route.query.code;
    }
  },
  methods: {

    gotocategory(category_id,is_jump,jump_url,key) {

      console.log("goto", this.navbarselected)
      if(is_jump === 1){
        this.$router.push({
          path: jump_url,
          query: {
            key: key,
            t:Date.now(),
          }
        });
      }else{
        this.$router.push({
          path: 'category_list',
          query: {
            id: category_id,
            key: key,
            t:Date.now(),
          }
        });
      }



    },
    goUrl(url){
      // this.reload();
      console.log("url", url);
      window.open(url,'_blank');
    },


  }
}

</script>

<style lang="scss" scoped>
.bottom {
  max-width: 1920px !important;
  //height: 450px;
  margin-left: auto;
  margin-right: auto;
  background-color:#082D59;
  .bottominfo{
    max-width: 1248px;
    margin-right: auto;
    margin-left: auto;
    color:#fff;
    display: flex;
    justify-content: flex-start;
    .bottom_left{
      color:#fff;
      width: 410px;
      margin-right: 100px;
      padding-top: 40px;
      .about{
        margin-bottom: 10px;
        margin-top: 10px;
        width: 410px;
        text-align-last: justify;
        display: inline-block;
        text-align: justify;
      }
    }
    .bottom_right{
      color:#fff;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      max-height:370px;
      //height: 350px;
      padding-top: 40px;
      h3{
        color:#fff;
      }
      .barmain{
        margin-bottom: 30px
      }
      .barblock{
        position: relative;
        width: 320px;
        line-height: 180%;
        padding-left: 10px;
        box-sizing: border-box;
        cursor: pointer;
        //margin-bottom: 50px
      }
      .barlink::before{
        content: '•';
      }

      .barlink:hover{
        //color:#CE1126;
        text-decoration: underline;
        list-style: disc;
      }
    }
  }
  .copyright{
    .text{
      max-width: 1248px;
      margin-right: auto;
      margin-left: auto;
      color:#fff;
      line-height:64px;
      margin-top: 40px
    }

    background-color:#002654;
    height:64px;
  }
  h1 {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    line-height: 45px;
  }

  .news {
    max-width: 1248px;
    height: 382px;
    margin-right: auto;
    margin-left: auto;
  }
}

</style>