 <template>
  <div class="topslogan">
    <div>  <img height="120" src="@/static/images/newlogo-150.jpg" /></div>
    <div class="slogans">
      <img src="@/static/images/slogan.png" />
<!--      <div class="slogan">Prioritize physical and mental health for a better life</div>-->
<!--      <div class="slogan">关注全民身体、心理和精神健康，追求更高的生命和生活质量</div>-->
<!--      <div class="slogan">Privilégier la santé physique et mentale pour une vie meilleure</div>-->
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
    }
  },
  created() {
    console.log("topslogan")
  },
  methods: {
    },
}

</script>

<style lang="scss" scoped>
.topslogan{
  display: flex;
  width: 1248px;
  margin-right: auto;
  margin-left: auto;
  background: #fff!important;
  height: 130px;

}
.slogans{
  //display: flex;
  //align-items: end;

  padding: 34px 20px 10px 20px;
  line-height:25px;
  font-size: 16px;
  .slogan{
    width: 480px;
    text-align:justify;
    display: inline-block;
    text-align-last: justify;
  }
}
</style>