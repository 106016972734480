 <template>
  <div class="topnav">
    <div  :class="mask !== false? 'mask' : ''"></div>
      <a-row class="navbar" type="flex">

<!--        <a-col class="logo" flex="100px">-->
<!--          <router-link to="/">-->
<!--&lt;!&ndash;            <img height="160" src="@/static/images/newlogo1.png" width="160"/>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-if="language ==='cn'">&ndash;&gt;-->
<!--&lt;!&ndash;                    <img height="160" src="@/static/images/1.png" width="136"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span v-if="language ==='en'">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img height="160" src="@/static/images/2.png" width="136"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span v-if="language ==='fr'">&ndash;&gt;-->
<!--&lt;!&ndash;                   <img height="160" src="@/static/images/3.png" width="136"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  </span>&ndash;&gt;-->

<!--          </router-link>-->
<!--        </a-col>-->
        <a-col class="navbar_group" flex="auto">
          <div class="navbar_tools">
            <div>
              <a-icon style="font-size: 14px;border:1px solid #fff;border-radius:50%;margin-right:5px" type="user"/>
              <span v-if="login_username!==''">
                <router-link class="links" to="/myinfo?my_id=1&choose=1"> <span style="margin-right: 10px">{{login_username}}</span>
                  <a-tag  v-if="is_check_email ==='-1'">
                     <span v-if="language ==='en'">
                     {{ dictionary.en.User}}
                  </span>
                     <span v-if="language ==='cn'">
                   {{ dictionary.cn.User}}
                  </span>

                  <span v-if="language ==='fr'">
                     {{ dictionary.fr.User}}
                  </span>
                  </a-tag>
                  <a-tag  v-if="is_check_email ==='1'">
                     <span v-if="language ==='en'">
                     {{ dictionary.en.Membre}}
                  </span>
                     <span v-if="language ==='cn'">
                   {{ dictionary.cn.Membre}}
                  </span>

                  <span v-if="language ==='fr'">
                     {{ dictionary.fr.Membre}}
                  </span>
                  </a-tag>
                </router-link>
              </span>
              <span v-if="login_username ===''||login_username ===null">
              <span v-if="language ==='cn'">
                    <router-link to="/reg" class="links"> {{ dictionary.cn.reg }}</router-link>  /   <router-link class="links" to="/login"> {{ dictionary.cn.login }}</router-link>
                  </span>
              <span v-if="language ==='en'">
                    <router-link to="/reg" class="links"> {{ dictionary.en.reg }}</router-link>  /   <router-link class="links" to="/login"> {{ dictionary.en.login }}</router-link>
                  </span>
              <span v-if="language ==='fr'">
                    <router-link to="/reg" class="links"> {{ dictionary.fr.reg }}</router-link>  /   <router-link class="links" to="/login"> {{ dictionary.fr.login }}</router-link>
                  </span>
                </span>
              <a-dropdown>
                <a class="ant-dropdown-link dropdown">
                  <a-icon style="margin-right:5px" type="global"/>

                  <span v-if="language ==='en'">
                   English
                  </span>
                  <span v-if="language ==='cn'">
                    中文
                  </span>
                  <span v-if="language ==='fr'">
                   Français
                  </span>
                  <a-icon style="margin-left:5px;" type="down"/>
                </a>
                <a-menu slot="overlay" @click="onChange">

                  <a-menu-item key="En">
                    English
                  </a-menu-item>
                  <a-menu-item key="cn">
                    中文
                  </a-menu-item>
                  <a-menu-item key="Fr">
                    Français
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>

          </div>
          <div class="navbar_meun">
            <a-menu
                :selectedKeys=navbarselected

                forceSubMenuRender
                mode="horizontal"

            >
              <template v-for="(item,index) in navbar">
<!--                {{navbar}}-->
                <a-menu-item v-if="item.child.length === 0" :key="item.key" @click="gotocategory(item.id,item.is_jump,item.jump_url,item.key)"
                             :class="item.id===navbarselected[0]?'ant-menu-submenu-selected':''"
                >
                  <span v-if="language ==='cn'">
                    {{ item.name.cn }}
                  </span>
                  <span v-if="language ==='en'">
                    {{ item.name.en }}
                  </span>
                  <span v-if="language ==='fr'">
                    {{ item.name.fr }}
                  </span>
                </a-menu-item>
                <a-sub-menu v-if="item.child.length > 0" :key="item.key"
                            :class="item.id===navbarselected[0]?'ant-menu-submenu-selected':''"
                >
                  <span slot="title" class="submenu-title-wrapper"

                        @click="gotocategory(item.id,item.is_jump,item.jump_url,item.key)">
                    <span v-if="language ==='cn'">
                    {{ item.name.cn }}
                  </span>
                  <span v-if="language ==='en'">
                    {{ item.name.en }}
                  </span>
                  <span v-if="language ==='fr'">
                    {{ item.name.fr }}
                  </span>
                  </span>
                  <a-menu-item v-for="(items,indexs) in item.child" :key="items.key" @click="gotocategory(items.id,items.is_jump,items.jump_url,item.key)">
                 <span v-if="language ==='cn'">
                   {{ items.name.cn }}
                  </span>
                    <span v-if="language ==='en'">
                   {{ items.name.en }}
                  </span> <span v-if="language ==='fr'">
                  {{ items.name.fr }}
                  </span>

                    <!--                  {{items.child.length}}-->
                  </a-menu-item>

                </a-sub-menu>
              </template>
            </a-menu>
          </div>
        </a-col>
      </a-row>
      <!--      {{navbar}}-->
    </div>
</template>

<script>

import Config from "@/config";
import Storage from "@/common/storage";

export default {
  inject: ["reload"],
  props: ['category_id','category_key','mask'],

  name: "topnav",
  data() {
    return {
      language: localStorage.language,
      uid: Storage.uid,
      login_username: Storage.name,
      is_check_email: Storage.is_check_email,
      is_student: Storage.is_student,
      navbar: sessionStorage.navbar,
      navbarselected: sessionStorage.navbarselected,
      // navbar: JSON.parse(sessionStorage.navbar),
      // navbarselected: JSON.parse(sessionStorage.navbarselected),
      displaylanguage: "中文",
      actice_id: "",
      // navbarselected: [],
      dictionary: Config.data.dictionary,
    }
  },
  created() {
    // this.getwebindex()
    if(sessionStorage){
      this.navbar = JSON.parse(sessionStorage.getItem('navbar'))
      this.navbarselected = JSON.parse(sessionStorage.getItem('navbarselected'))
    }

    // this.navbarselected = JSON.parse(this.navbarselected)
    console.log('this.category_id',this.category_id[0])
    if(this.category_id[0]==='0'){
      this.navbarselected =[]
      this.navbarselected.push(27)
    }else {
      this.navbarselected =[]
      this.navbarselected.push(this.category_id*1)
    }

    console.log('this.navbarselected',this.navbar)
    console.log('this.navbarselected',this.navbarselected)
    console.log('this.category_id',this.category_id)
    // console.log("login_name",this.login_username,typeof(this.login_username))
    // console.log("mask",this.mask)
  },
  watch: {
    openKeys(val) {
      console.log('openKeys', val);
    },
    // 利用watch方法检测路由变化：
    $route: function (to, from) {
      console.log("watch", to, from)

      if (to.fullPath !== from.fullPath) {
        this.currentPage = this.$route.query.code;//获取参数
      }

    }
  },

  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate", to, from, next)
    next();
    if (to.fullPath !== from.fullPath) {
      this.currentPage = this.$route.query.code;
    }
  },
  methods: {
    goUrl(){
      this.reload();
    },
    async onChange({key}) {
      let that = this
      console.log(`Click on item ${key}`);
      that.language = key;
      if (key === "cn") {
        that.displaylanguage = "中文"
        localStorage.setItem('language', "cn");
        that.language = localStorage.getItem('language')
        console.log("reflesh", that.language,that.displaylanguage)
        that.reload()
        that.$forceUpdate()
      }
      if (key === "En") {
        that.displaylanguage = "English"
        localStorage.setItem('language', "en");
        that.language = localStorage.getItem('language')
        console.log("reflesh", that.language,that.displaylanguage)
        that.reload()
        that.$forceUpdate()
      }
      if (key === "Fr") {
        that.displaylanguage = "Français"
        localStorage.setItem('language', "fr");
        that.language = localStorage.getItem('language')
        console.log("reflesh", that.language,that.displaylanguage)
        that.reload()
        that.$forceUpdate()
      }
      console.log("localStorage", that.language,that.displaylanguage)
    },
    reflesh(){
      console.log("reflesh1", this.language)
      this.$router.push({path:'/refresh',query:{path:this.$route.fullPath}})
    },

    gotocategory(category_id,is_jump,jump_url,key) {

      console.log("goto", this.navbarselected)
      if(is_jump === 1){
        this.$router.push({
          path: jump_url,
          query: {
            key: key,
            language: this.language,
            t:Date.now(),
          }
        });
      }else{
        this.$router.push({
          path: 'category_list',
          query: {
            id: category_id,
            key: key,
            language: this.language,
            t:Date.now(),
          }
        });
      }



    },

  }
}

</script>

<style lang="scss" scoped>
.links{
  color: #fff;
}
.links:hover{
  color: #E0E4E5;
}
.topnav {
  display: flex;

  position: relative;

  .mask {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #002654 100%);
    position: absolute;
    display: block;
    z-index: 100;
    height: 200px;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
  }

  /deep/ .ant-menu-submenu-title:hover, .ant-menu-item:hover {
    color: #fff !important;
    font-size: 20px !important;

  }

  .ant-menu-submenu-active, .ant-menu-item-active {
    color: #fff !important;
    border-bottom: 2px solid #fff !important;
  }

  .ant-menu-item-selected, .ant-menu-submenu-title, .ant-menu {
    color: #fff !important;
    border-bottom: 2px solid #fff !important;
    padding-bottom: 0px !important;
    font-size: 20px !important;
    font-weight: bold !important;
  }

  .navbar {
    position: absolute;
    display: flex;
    height: 100px;
    z-index: 100;
    width: 100%;

    .logo {
      width: 154px;
    }

    .navbar_group {
      max-width: 1248px;
      margin-right: auto;
      margin-left: auto;
      height: 100%;
      padding-top: 24px;
      justify-content:space-between;
      //justify-content: flex-end;

      .dropdown {
        color: #fff;
        margin-left: 10px;
      }

      .navbar_meun {
        display: flex;
        justify-content: flex-start;

        /deep/ .ant-menu {
          background-color: unset !important;
          color: #fff;
          font-size: 16px;
          font-weight: bold;

          .ant-menu-item:last-child {
            padding-right: 0 !important;
          }
        }

        /deep/ .ant-menu-horizontal {
          border-bottom: unset !important;
        }
      }

      .navbar_tools {
        color: #fff;
        display: block;
        text-align: right;
      }
    }
  }


}
/deep/.ant-menu-submenu-selected {
  color: #fff;
  border-bottom: 2px solid #fff;
}
</style>